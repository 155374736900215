export default defineNuxtRouteMiddleware((to, from) => {
  const store = useMainStore()
  store.SET_FROM_URL(to.fullPath)

  if (!to.query.collector_token && from.query.collector_token) {
    const query = { ...from.query, ...to.query }
    to.query = query

    return navigateTo(to, {replace: true});
  }
})
