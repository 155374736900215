export default defineNuxtPlugin((nuxtApp) => {
  const options = useRuntimeConfig().public.tracking

  if (useRoute().query.is_pur_abo !== 'true') {
    addToHeader(options.queryString)
  }

  const gtm = new GTM(options)
  gtm.init()

  useRouter().beforeEach((to, from, next) => {
    const widgetOrNotificationRoute =
      to.path.includes('widget') || to.path.includes('notification/register')

    if (!widgetOrNotificationRoute) {
      track(to.path)
    }

    next()
  })

  return {
    provide: {
      track,
      gtm,
    }
  }
})

const addToHeader = (queryString: string) => {
  const gtagScript = document.getElementById('gtag')

  if (gtagScript) {
    return
  }

  // Add script tag to head
  const script = document.createElement('script')
  script.async = true
  script.id = 'gtag'
  ;(script.src = '//www.googletagmanager.com/gtm.js' + '?' + queryString),
    document.head.appendChild(script)
}

const track = (path: string) => {
  trackMe(path)
}

const state = {
  tracked: []
}

const mutations = {
  TRACK_PAGE(state: any, page: string) {
    state.tracked.push(page)
  }
}

const trackMe = (path: string) => {
  const options = useRuntimeConfig().public.tracking
  const prefix = options.prefix

  const mappings: Record<string, string|any> = {
    '/me/challenges': `/${prefix}/challenge`,
    '/me/posts/': `/${prefix}/challenge/detail`,
    'challenge/confirm': `/${prefix}/challenge/confirmation`,
    '/me/overview': `/${prefix}/start`,
    'ueberaschung': `/${prefix}/ueberaschung`,
    '/me/rewards': `/${prefix}/reward/shop/uebersicht`,
    '/me/rewards/detail/': {
      'buy': `/${prefix}/reward/shop/detail`,
      'raffle': `/${prefix}/reward/shop/gewinnspiel/detail`
    },
    'checkout/start': `/${prefix}/reward/shop/checkout/start`,
    'checkout/address': `/${prefix}/reward/shop/checkout/addresse`,
    'checkout/confirm': `/${prefix}/reward/shop/checkout/confirmation`,
    'raffle': `/${prefix}/reward/shop/gewinnspiel/uebersicht`,
    'raffle/start': `/${prefix}/reward/shop/gewinnspiel/checkout/start`,
    'raffle/confirm': `/${prefix}/reward/shop/gewinnspiel/checkout/confirmation`,
    '/me/profile': `/${prefix}/profile`,
    '/me/advent': `/${prefix}/advent`,
    '/me/halloween': `/${prefix}/halloween`,
    '/me/voucher': `/${prefix}/voucher/start`,
    'voucher/success': `/${prefix}/voucher/success`,
    'notification/register/register': `/${prefix}/notification/register/register`,
    'notification/register/close': `/${prefix}/notification/register/close`,
    '/notification/detail/': 'notification/detail',
  }
  /* eslint-enable */
  const id = path.match(/[0-9]+$/)

  let virtualPath = mappings[path]

  const rewardStore = useRewardsStore()

  if (id) {
    const url = path.match(/(\D)/g)!.join('')

    let mapping = mappings[url]

    if (typeof mapping === 'object') {
      const reward = rewardStore.getReward(Number(id[0]))
      if (Object.entries(reward).length !== 0) {
        mapping = mapping[reward.payment_type]
      } else {
        mapping = mapping.buy
      }
    }

    virtualPath = `${mapping}/${id[0]}`
  }

  virtualPath = virtualPath || `/${prefix}${path}`

  if (path.includes('widget') || path.includes('notification')) {
    virtualPath = path
  }

  if (process.client) {
    const { $gtm } = useNuxtApp()
    $gtm.pushEvent({
      event: 'virtualPageview',
      virtualPagePath: virtualPath
    })

    console.log('tracking: ' + virtualPath)
  }
}

// Google Tag Manager Class to be injected
class GTM {
  public options;

  constructor(options: any) {
    this.options = options
  }

  init() {
    window[this.options.layer] = window[this.options.layer] || []

    this.pushEvent({
      event: 'gtm.js',
      'gtm.start': new Date().getTime()
    })
  }

  pushEvent(obj: any) {
    try {
      if (!window || !window[this.options.layer]) {
        throw new Error('missing GTM dataLayer')
      }
      if (typeof obj !== 'object') {
        throw new TypeError('event should be an object')
      }
      if (!obj.hasOwnProperty('event')) {
        throw new Error('missing event property')
      }
      window[this.options.layer].push(obj)
    } catch (err) {
      console.error('[ERROR] [GTM]', err)
    }
  }
}
