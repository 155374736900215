// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/vue';
export default defineNuxtPlugin((app) => {
  // register Swiper custom elements
  register();
  app.vueApp.component('Swiper', Swiper);
  app.vueApp.component('SwiperSlide', SwiperSlide);
})
