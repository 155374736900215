import { useMeStore } from "~/store/me";
import { FetchOptions } from "ofetch"

export default function useApi() {
  const runRequest = async <T = any>(url: string, options: FetchOptions): Promise<T> => {
    let baseURL = url;

    if (!url.startsWith('http')) {
      baseURL = '/api' + url;
    }

    options.query = options.query || {}

    const meStore = useMeStore()

    options.query.collector_token = meStore.collector_token

    try {
      const data = await $fetch<T>(baseURL.toString(), options as any)

      return data
    } catch (e: any) {
      useBugsnag().notify(e, (event) => {
        event.addMetadata('api', { metaData: {baseURL, options} })
        event.addMetadata('response', { metaData: {response: e.data} })
      })
      throw e;
    }
  }

  return {
    $get: async <T = any>(url: string, options: any = {}): Promise<T> => runRequest(url, {method: 'GET', ...options}),
    $post: async <T = any>(url: string, body: any = {}, options: any = {}): Promise<T> => runRequest(url, {method: 'POST', body, ...options}),
    request: runRequest,
  };
}
