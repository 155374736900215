export default {
  currencies: {
    laendlepunkte: 'Ländlepunkte',
    main: 'Ländlepunkte',
  },
  menu: {
    overview: 'Übersicht',
    challenges: 'Mitmachen',
    rewards: 'Prämien',
    profile: 'Profil',
    halloween: 'Halloween',
    advent: 'Adventskalender',
    blacklaendleweek: 'Black Week',
    newyear: 'Vorsätze-Woche',
  },
  pages: {
    overview: {
      headertext:
        'Sammle Ländlepunkte und belohne dich mit tollen Preisen und Auszeichnungen.',
    },
    challenges: {
      header: 'Mitmachen',
      headertext:
        'Du liebst Herausforderungen? Mit jeder erfolgreichen Challenge sammelst du weitere Ländlepunkte.',
      tiles: {
        prizewheel: 'Glücksrad',
        quiz: 'Ländlequiz',
      },
    },
    rewards: {
      header: 'Prämien',
      headertext:
        'Hier kannst du deine gesammelten Ländlepunkte einlösen. Tolle Preise und exklusive Angebote warten auf dich. Viel Spaß!',
    },
    my_rewards: {
      header: 'Meine Preise',
      menu: {
        reward: 'Prämien',
        raffle: 'Gewinnspiele',
      },
      show_more: 'Mehr anzeigen',
    },
    profile: {
      header: 'Profil',
      menu: {
        profile: 'Mein Profil',
        leaderboard: 'Bestenliste',
      },
      headertext: 'Hier findest du alle deine Daten im Überblick.',
    },
    avatar: {
      header: 'Profil',
      headertext: 'Hier kannst du dein Profilbild ändern.',
      subheadline: 'Profilbild ändern',
      explanation:
        'Dein Profilbild wird für VOL.AT und für Ländlepunkte übernommen.',
      buttons: {
        upload: 'Bild hochladen',
        remove: 'Bild entfernen',
        crop: 'Auswählen',
        cancel: 'Abbrechen',
        yes: 'Ja',
        no: 'Nein',
      },
      modals: {
        delete: {
          title: 'Bist du sicher?',
          description: 'Bist du sicher, dass du das Bild löschen willst?',
        },
        deleted: {
          title: 'Bild gelöscht',
          description:
            'Das Profilbild wird in Kürze nicht mehr angezeigt werden.',
        },
        uploaded: {
          title: 'Bild hochgeladen',
          description: 'Das neue Profilbild wird in Kürze sichtbar sein.',
        },
      },
    },
    leaderboard: {
      header: 'Bestenliste',
      headertext:
        'Hier siehst du deinen aktuellen Punktestand und den unserer Top-Spieler.',
      subheadings: {
        total: 'Gesamt',
        thisweek: 'Diese Woche',
      },
      topplayers: 'Top-Spieler',
      myrange: 'Meine Punkte',
      nothingthisweek:
        'Sobald du diese Woche Ländlepunkte gesammelt hast, siehst du hier deine Platzierung.',
      anonymous:
        'Der Bereich "Meine Punkte" ist nur für Registrierte Nutzer verfügbar.',
    },
    error: {
      header: 'Ups, das hätte nicht passieren dürfen',
      headertext:
        'Versuche die Oberfläche erneut zu öffnen oder versuche es später noch einmal',
      token: 'Dein Support Token: {token}',
    },
    maintenance: {
      header: 'Wartungsarbeiten',
      headertext: 'Wir sind gleich wieder da!',
    },
    faq: {
      header: 'FAQ',
      headertext:
        'Hier werden die häufigsten Fragen zu den Ländlepunkten beantwortet.',
    },
    halloweenspecial: {
      header: 'Happy Halloween',
      headertext:
        'Zu Halloween gibt es 13 Tage (19.10. bis 31.10.) täglich ein neues Gewinnspiel, bei dem exklusive Preise verlost werden.\n\nAls Hauptpreis wird unter allen Teilnehmern ein Tablet verlost! Wir wünschen ein gruseliges Halloween und viel Glück!',
    },
    advent: {
      header: 'Adventskalender',
      headertext:
        'Täglich exklusive Preise gewinnen - so vergehen die Tage bis Weihnachten im Nu. Unter allen Teilnehmenden verlosen wir zusätzlichen einen sensationellen Hauptpreis. Wir drücken die Daumen und wünschen eine schöne (Vor-)Weihnachtszeit!',
    },
    adventspecial: {
      header: 'Adventskalender',
      headertext:
        'Bereit für den Advent? Gewinne täglich einen Adventskalender',
    },
    blacklaendleweek: {
      header: 'Black-Ländle-Week',
      headertext:
        'Der Black Friday ist uns nicht genug, wir machen daraus eine Black-Friday Woche - mit regionalen Preisen!<br /><br />Wir wünschen dir viel Glück! ',
    },
    special: {
      back: 'Zurück zum Special',
    },
    voucher: {
      header: 'Gutscheine',
      headertext:
        'Hier hast du die Möglichkeit, Ländlepunkte-Gutscheine zu scannen.',
      anon: 'Melde dich an, um mitzumachen',
      won: 'Du hast gewonnen:',
      button: 'Einlösen',
      yourcode: 'Dein Gutscheincode: {id}',
      alreadyParticipated: 'Du hast bereits teilgenommen:',
    },
    vplus: {
      header: 'Deine V+ Vorteile',
      headertext:
        'Hier siehst du alle V+ Vorteile auf einen Blick. Tolle Preise und exklusive Angebote für V+ Abonnenten warten hier. Viel Spaß!',
    },
    index: {
      open_question: 'Wo wollen Sie die Ländlepunkte öffnen?',
      open_browser: 'Im Browser',
      open_app: 'In der App',
      learn_more: 'Mehr erfahren',
    },
  },
  components: {
    profile: {
      username: 'Benutzername:',
      mail: 'Email-Adresse:',
      placeholders: {
        gender_w: 'weiblich',
        gender_m: 'männlich',
        salutation_sir: 'Herr',
        salutation_mrs: 'Frau',
        salutation: 'Andrede',
        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail',
        telephone: 'Telefonnummer',
        street: 'Straße',
        street_nr: 'Nr.',
        postcode: 'PLZ',
        city: 'Ort',
        country: 'Land',
        birthday: 'Geburtsdatum TT.MM.JJJJ',
        pw_current: 'Aktuelles Passwort',
        pw_new: 'Neues Passwort',
        pw_new_confirm: 'Neues Passwort bestätigen',
      },
      errors: {
        empty:
          'Beim Speichern ist leider ein Fehler aufgetreten. Bitte versuche es später nocheinmal.',
        nothing_changed:
          'Die Daten haben sich nicht geändert, es wurde nichts gespeichert.',
        new_pws_not_matching: 'Deine neuen Passwörter stimmen nicht überein.',
        pw_field_empty: 'Alle Felder müssen ausgefüllt werden.',
        pw_too_short: 'Das neue Passwort muss mindestens 6 Zeichen lang sein.',
        old_pw_wrong: 'Das aktuelle Passwort ist nicht korrekt.',
        new_old_pw_same:
          'Das neue Passwort ist dasselbe wie das aktuelle Passwort.',
        outdated_app: 'Bitte update die App um das Profil nutzen zu können.',
        outdated_script:
          'Es sieht so aus als wäre dein Cache veraltet, bitte lösche deinen Browsercache!',
        anonymous:
          'Das Profil und die Freundefunktion ist nur für Registrierte Nutzer verfügbar.',
        unknown:
          'Da ist wohl was schiefgelaufen, das tut uns leid. Versuche es später erneut.',
      },
      points_info: 'Jetzt Profil vervollständigen und {currency} absahnen.',
      edit_hint: 'Klappe die Box auf, um dein Profil zu bearbeiten.',
      save_info: 'Dein Profil wurde erfolgreich gespeichert.',
      password_info: 'Das Passwort muss mindestens 6 Zeichen lang sein.',
      save: 'Änderungen Speichern',
      change_password: 'Passwort ändern',
      change_password_success: 'Passwort wurde erfolgreich geändert.',
      back_to_profile: 'Zurück zum Profil',
      level_progress: 'Nur noch {percent}% bis zum nächsten Level',
      max_level: 'Wow, du hast das höchste Level erreicht. Glückwunsch!',
      max_anonymous_level: 'Melde dich an um Level aufzusteigen!',
    },
    optout: {
      optout: 'Aussteigen',
      loading: 'Einen Moment bitte',
      info_title: 'Ganz sicher?',
      info_text:
        'Wir möchten VOL.AT gemeinsam mit euch noch besser machen.<br><br>Möchtest du die Ländlepunkte wirklich nicht mehr sehen?',
      question_title: 'Eine Frage noch!',
      question: 'Warum möchtest du die Ländlepunkte nicht mehr?',
      answers: {
        distracts: 'Lenkt von den News ab',
        noneed: 'Brauche ich nicht',
        dontknow: 'Weiß nicht',
      },
    },
    dailyreward: {
      title: 'Das Ländlepunkte Glücksrad',
      loading: 'Einen Moment bitte',
      spin: 'Versuch dein Glück',
      anonymous: 'Logge dich ein um zu drehen!',
      congrats: 'Glückwunsch',
      finished: 'Geschafft',
      ohno: 'Oh nein',
      success_points_text: 'Du hast {reward} {currency} gewonnen!',
      success_custom_text:
        'Du hast den "{reward}" gewonnen. Damit kannst du alle V+ Artikel 24 Stunden kostenlos lesen. Du kannst deinen Tagespass sofort oder jederzeit im Ländlepunkte-Shop unter dem Menüpunkt „Profil“ aktivieren.',
      success_reward_text:
        'Du hast die Prämie "{reward}" gewonnen! Mit dem Klick auf "Bestätigen" akzeptierst du die Teilnahmebedingungen und unsere AGBs.',
      success_nothing_text:
        'Leider nichts gewonnen. Komm später wieder und drehe noch einmal!',
      success_retry_text:
        'Leider nichts gewonnen, versuch es doch gleich nochmal.',
      comeback_tomorrow: 'Komm später wieder und drehe noch einmal!',
      confirm_delivery_text:
        'Damit wir dir deine Prämie zusenden können, hinterlasse uns doch bitte deine Adresse. Diese wird für das nächste mal in deinem Profil gespeichert.',
      text_save_success_digital:
        'Du erhälst in der nächsten Zeit eine E-Mail von uns.',
      text_save_success_delivery: 'Der Preis wird dir in Kürze zugesendet.',
      text_save_error:
        'Fehler beim Speichern deiner Daten. Bitte überprüfe deine Daten im Profil.',
      error: 'Ups',
      error_text:
        'Das tut uns leid, aber da ist wohl was schief gelaufen. Probiere es später wieder.',
      buttons: {
        confirm: 'Bestätigen',
        premium: 'Zu den Artikeln',
        activate: 'Jetzt aktivieren',
        close: 'Schließen',
      },
    },
    challenges: {
      back: 'Zurück zu den Challenges',
      quiz: {
        question: 'Frage',
        questionTitle: 'Frage {position}/{count}',
        results: {
          congrats: 'Hurra!',
          almost: 'Fast!',
          allcorrect:
            'Du hast alle {count} Fragen richtig beantwortet! Dafür bekommst du {reward} {currency}!',
          somecorrect:
            'Du hast {correct} von {count} Fragen richtig beantwortet. Dafür bekommst du {reward} {currency}!',
          nocorrect:
            'Du hast {correct} von {count} richtig beantwortet! Versuche es gleich nochmal.',
        },
        error: 'Da ist wohl was schiefgelaufen. Probiere es bitte noch einmal!',
        buttons: {
          send: 'Absenden',
          proceed: 'Weiter',
        },
        multiple_answers_possible: 'Mehrere Antworten möglich',
        answer: {
          correct: 'Richtig!',
          wrong: 'Leider falsch!',
        },
      },
    },
    posts: {
      survey: 'Umfrage',
      survey_open_placelholder: 'Deine Antwort ...',
      quiz_external_link:
        'Als Dankeschön für deine Teilnahme kannst du unter diesem <u>Link</u> vier Wochen lang den V+ Zugang testen.',
    },
    rewards: {
      payment_types: {
        raffle: 'Gewinnspiel',
        fund: 'Einlösen',
        buy: 'Einlösen',
        none: 'Abholen',
      },
      reward_types_card: {
        raffle: 'Gewinnspiel',
        buy: 'Prämie',
        fund: 'Spendenaktion',
        none: 'Abholen',
        exclusiveDeal_buy: 'Exklusive V+ Prämie',
        exclusiveDeal_raffle: 'Exklusives V+ Gewinnspiel',
      },
      delivery_type: {
        digital: 'Digital per Mail',
        delivery: 'Versand per Post',
        external: 'Link',
        coupon: 'Coupon',
        custom: 'Digital',
        pickup: 'Abholung bei Russmedia, Schwarzach',
      },
      buttons: {
        redeem: 'Einlösen',
        not_available: 'Nicht Verfügbar',
        participate: 'Teilnehmen',
        continue: 'Weiter',
        fund: 'Jetzt Spenden',
        allin: 'Alles einsetzen',
        subscribe: 'Hier loslegen',
      },
      my_rewards: {
        state: {
          digital: {
            waiting: 'in Bearbeitung',
            active: '',
            done: 'versendet',
            canceled: 'storniert',
          },
          delivery: {
            waiting: 'in Bearbeitung',
            active: '',
            done: 'versendet',
            canceled: 'storniert',
          },
          pickup: {
            waiting: 'in Bearbeitung',
            active: 'Abholbereit',
            done: 'abgeholt',
            canceled: 'storniert',
          },
          coupon: {
            waiting: 'jetzt einlösen',
            active: 'aktiv',
            done: 'eingelöst',
            canceled: 'verpasst',
          },
          custom: {
            waiting: 'jetzt aktivieren',
            active: 'aktiv',
            done: 'bereits aktiviert',
            canceled: 'verpasst',
          },
        },
        cancel: {
          title: 'Prämie stornieren',
          info_text:
            'Diese Prämie wurde noch nicht abgeholt oder versandt. Möchtest du sie stornieren?',
          cancel: 'Stornieren',
          detail:
            'Bist du sicher, dass du die Prämie "{reward}" stornieren möchtest?',
        },
        custom: {
          title: 'Pass aktivieren',
          info_text:
            'Dieser Pass wurde noch nicht aktiviert. Möchtest du ihn jetzt aktivieren?',
          activate: 'Aktivieren',
          modal: {
            title: '{reward} aktivieren',
            description:
              'Aktiviere jetzt deinen {reward} und lies alle unsere V+ Artikel kostenlos.',
          },
        },
      },
      my_raffles: {
        tickets_count: 'Du besitzt {amount} Los | Du besitzt {amount} Lose',
        more_tickets: 'Weitere Lose',
        raffle_state_won: 'gewonnen',
        raffle_state_lost: 'nicht gewonnen',
        raffle_state_expired: 'abgelaufen',
        raffle_state_pending: 'Das Gewinnspiel wird ausgelost',
        raffle_state_canceled: 'abgebrochen',
      },
      type: 'Art',
      details: 'Details',
      delivery: 'Übermittlung',
      category: 'Kategorie',
      cost: 'Kostet',
      free: 'Kostenlos',
      redeem: 'Einlösen',
      collect: 'Abholen',
      redeemed_rewards: 'Eingelöste Prämien',
      redeemed_rewards_history: 'Verlauf',
      insufficient_funds:
        'Du hast leider nicht genügend Ländlepunkte für diese Prämie, sammle weiter!',
      insufficient_funds_raffle_ticket:
        'Du hast leider nicht genügend Ländlepunkte für so viele Lose!',
      insufficient_funds_fund_ticket:
        'Du hast leider nicht genügend Ländlepunkte!',
      anonym_collector:
        'Du bist nicht registriert! Prämien können nur von registrierten Usern eingelöst werden',
      subscribers_only:
        'Hol dir jetzt deinen V+ Zugang, um bei diesem exklusiven V+ Gewinnspiel teilnehmen zu können.',
      subscribers_only_buy:
        'Hol dir jetzt deinen V+ Zugang, um diese exklusive Prämie einlösen zu können.',
      back: 'Zurück zu den Prämien',
      no_orderedrewards:
        'Du hast noch keine Prämien eingelöst. Warum schaust du nicht mal in unseren Shop, sicher ist auch etwas für dich dabei.',
      no_orderedraffles:
        'Du hast noch keine Lose, um an Gewinnspielen teilzunehmen. Warum schaust du nicht mal in unseren Shop, sicher ist auch etwas für dich dabei.',
      to_shop: 'Zum Prämien Shop',
      limit: 'Limit',
      limits: {
        daily: 'täglich einlösbar',
        weekly: 'wöchentlich einlösbar',
        monthly: 'monatlich einlösbar',
        bimonthly: 'zweimonatlich einlösbar',
        quarterly: 'vierteljährlich einlösbar',
        semiannually: 'halbjährlich einlösbar',
        annually: 'jährlich einlösbar',
      },
      participate_limit: 'Teilnahmelimit:',
      terms_of_participation: 'Teilnahmebedingungen',
      terms_reward:
        'Die Russmedia Digital GmbH übernimmt keine Haftung für eingelöste Prämien, die zudem vom Umtausch ausgeschlossen sind. Abzuholen sind die Preise innerhalb eines Monats - eine Barablöse ist nicht möglich, der Verkauf an Dritte ist untersagt.  Pro User darf nur ein Benutzerkonto verwendet werden. Bei limitierten Prämien ist daher eine mehrfache Teilnahme nicht gestattet. Die tatsächlichen Prämien können von den Symbolbildern abweichen. ',
      agb: 'Allgemeine Geschäftsbedingungen',
      none: 'Keines',
      once: 'Einmalig',
      tab_title: 'Prämien & Gewinnspiele',
      modal: {
        title_almost_finished: 'Fast geschafft',
        title_loading: 'Einen Moment bitte',
        reservate_text:
          'Wir prüfen gerade ein paar Details, gleich gehts weiter.',
        confirm_text:
          'Du bist dabei {price} Ländlepunkte für diese Prämie einzulösen. Mit dem Klick auf "Einlösen" bestätigst du diesen Vorgang und akzeptierst die Teilnahmebedingungen sowie unsere AGBs.',
        confirm_raffle_text:
          'Mit einem Klick auf „Einlösen“ nimmst du an der Auslosung teil und akzeptierst damit die Teilnahmebedingungen und unsere AGB.',
        digital_email:
          'Weitere Infos werden dir an folgende E-Mail Adresse gesendet: {email}',
        mail_missing_text:
          'Da diese Prämie Digital ist und wir von dir noch keine E-Mail Adresse hinterlegt haben bitten wir dich uns diese anzugeben.',
        telephone_missing_text:
          'Möchtest du deine Telefonnummer hinterlassen, damit wir dich – falls du gewinnen solltest – besser erreichen können? Optional.',
        address_missing_text:
          'Damit wir dir deine Prämie zusenden können, hinterlasse uns doch bitte deine Adresse. Diese wird für das nächste mal in dein Profil gespeichert.',
        confirm_mail_text: 'Bitte bestätige deine E-Mail Adresse.',
        confirm_telephone_text: 'Bitte bestätige deine Telefonnummer.',
        confirm_address_text:
          'Bitte bestätige deine Adressdaten. Änderungen werden nicht in dein Profil gespeichert!',
        mail_error_text: 'Bitte gebe eine gültige E-Mail Adresse an!',
        telephone_error_text: 'Bitte gebe eine gültige Telefonnummer an!',
        address_error_text:
          'Bei deinen Kontaktdaten stimmt etwas nicht ganz. Bitte prüfe deine Eingaben!',
        title_error: 'Ups',
        title_success: 'Glückwunsch',
        title_success_raffle: 'Vielen Dank',
        title_success_fund: 'Vielen Dank',
        text_success_fund:
          'Vielen Dank für deine Spende von {price} Ländlepunkten! Teile den Link auf Social Media und hilf uns dabei, unser Ziel zu erreichen und Kinderaugen zum Strahlen zu bringen!',
        text_completed_fund:
          'Die Weihnachtswichtel sind unterwegs und bringen Kinderaugen unterm Baum zum Strahlen. Vielen Dank für deine Unterstützung!',
        already_completed_fund:
          'Die Weihnachtswichtel sind bereits unterwegs und bringen Kinderaugen unterm Baum zum Strahlen. Schau dich doch bei den anderen Wünschen um, damit so viele Kinder wie möglich ein schönes Weihnachtsfest haben.',
        text_success_digital:
          'Du hast diese Prämie erfolgreich gegen {price} Ländlepunkte eingelöst. Wir haben dir eine Bestätigungsmail zugesendet, du findest deine eingelösten Prämien auch unter "Meine Prämien" in deinem Profil.',
        text_success_raffle:
          'Mit etwas Glück gehört der Preis schon bald dir. Wir kontaktieren die Gewinner nach der Auslosung.',
        title_raffle_tickets: 'Gewinnspielteilnahme',
        text_raffle_tickets: 'Wie viele Ländlepunkte möchtest du einsetzen?',
        hint_raffle_tickets:
          'Tipp: Je höher dein Einsatz, desto höher deine Gewinnchance.',
        text_raffle_tickets_cost: 'Eingesetzte Punkte',
        text_raffle_tickets_participations: 'Teilnahmen',
        text_fund_tickets: 'Wie viele möchtest du spenden?',
        text_fund_tickets_cost: 'Spende gesamt',
        title_success_coupon: 'Glückwunsch',
        text_success_coupon:
          'Du findest den Teilnahmecoupon unter dem Menübutton „Profil“',
        to_reward: 'Zum Coupon',
        to_my_reward: 'Zu Meinen Preisen',
      },
      fields: {
        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail',
        telephone: 'Telefonnummer (+## ...)',
        address: 'Adresse',
        street: 'Straße',
        street_nr: 'Nr.',
        postcode: 'PLZ',
        city: 'Stadt',
        country: 'Land',
        birthday: 'Geburtsdatum TT.MM.JJJJ',
      },
      errors: {
        empty:
          'Da ist wohl was schiefgelaufen, das tut uns leid. Versuche es später erneut.',
        form_incomplete: 'Bitte fülle alle Felder aus.',
        already_reserved_item:
          'Da ist wohl was schiefgelaufen, versuche es in ein paar Minuten erneut.',
        reservation_failed:
          'Da ist wohl was schiefgelaufen, versuche es in ein paar Minuten erneut.',
        soldout:
          'Diese Prämie ist leider nicht mehr verfügbar. Halte die Augen offen, denn in unserem Prämienshop findest du regelmäßig weitere tolle Preise.',
        insufficient_funds:
          'Du hast leider nicht genügend Ländlepunkte für diese Prämie.',
        payment_failed:
          'Leider ist bei deinem Kauf ein Fehler aufgetreten, versuche es in ein paar Minuten erneut.',
        redeemable_count:
          'Du hast diese Prämie bereits bestellt und kannst daher keine weiteren Einlösungen vornehmen.',
        redeemable_suspended:
          'Du hast diese Prämie bereits bestellt. Schau zu einem späteren Zeitpunkt vorbei, um erneut einlösen zu können.',
        typenotallowed: 'Da ist wohl was schiefgelaufen, das tut uns leid.',
        type_cant_be_buyed: 'Da ist wohl was schiefgelaufen, das tut uns leid.',
        type_cant_be_funded: 'Da ist wohl was schiefgelaufen, das tut uns leid.',
        redeem_from: 'Dieses Gewinnspiel hat noch nicht gestartet.',
        redeem_to:
          'Dieses Gewinnspiel ist vorbei, der Gewinner wird von uns benachrichtigt.',
        redeem_to_advent:
          'Das Gewinnspiel ist vorbei, schau doch mal in das heutige Türchen von unserem Adventskalender, dort findest du täglich neue tolle Gewinnspiele.',
        title: 'Oh Nein!',
        404: 'Die Prämie wurde leider nicht gefunden',
        no_hash_token:
          'Es ist leider ein technischer Fehler aufgetreten. Bitte prüfe, ob du eingeloggt bist oder versuche, dich neu anzumelden. Wir arbeiten bereits an einer Lösung für dieses Problem.',
      },
      sliders: {
        top_rewards: 'Top Prämien',
        top_raffles: 'Top Gewinnspiele',
      },
      required_info: '*Pflichtfeld',
      raffleform_title: 'Teilnahmeformular',
      raffle_terms: 'Ich akzeptiere die Teilnahmebedingungen*',
      dsgvo: 'Bitte beachte unsere Datenschutzinformationen',
      dsgvo_fulltext:
        'Ja, ich möchte von Vorteilsangeboten profitieren und bin damit einverstanden, dass die Russmedia Digital GmbH meine Daten (= Name, Adresse, E-Mail-Adresse und Telefonnummer) zwecks Kontaktaufnahme per Brief, Telefon, Messenger Dienst oder E-Mail zur Information über Produkte und aktuelle Angebote der Russmedia-Gruppe (Russmedia GmbH, NEUE Zeitungs GmbH, Russmedia IT GmbH, Russmedia Verlag GmbH, Russmedia Digital GmbH, Antenne Vorarlberg GmbH. Alle Unternehmen mit Sitz in Gutenbergstraße 1, 6858 Schwarzach, Österreich) verarbeitet sowie zu diesem Zweck an Unternehmen der Russmedia-Gruppe weitergibt. Diese Einwilligung kann ich jederzeit widerrufen.',
      sold_out: 'Leider verpasst',
      available_shortly: 'In Kürze',
      exclusive_deal: 'Hol dir deinen V+ Zugang',
      only_with_subscription: 'Exklusiv mit V+ Zugang',
      subscribe: 'Hier loslegen',
      explore_more: 'Weitere Prämien entdecken',
      sponsor: 'Mit freundlicher Unterstützung von',
      fund: 'Gespendete {currency}',
      fund_again: 'Noch einmal Spenden',
      share: {
        fund: 'Auf VOL.AT habe ich gerade meine Ländlepunkte gespendet, sei auch du dabei um Kindern einen Herzenswunsch zu erfüllen! Hier geht’s zur Charity-Aktion',
      },
      fund_current: 'Aktueller Spendenstand',
      load_more: 'Mehr laden',
      coupon: {
        yourcode: 'Dein Code:',
        warning_text: 'Der Coupon ist nur einmal einlösbar.',
        redeem: 'Coupon einlösen',
        redeem_now: 'Jetzt Code aktivieren',
        redeeming: 'löse ein ...',
        redeemed: 'Coupon eingelöst',
        redeem_later: 'Später einlösen',
        redeem_until: 'Einlösbar bis {time}',
        expired: 'Abgelaufen',
        no_internet_warning_title: 'Oh nein!',
        no_internet_warning:
          'Keine Verbindung zum Server möglich! Bitte stelle sicher, dass du eine aktive Internetverbindung hast',
        confirm: {
          title: 'Bist du sicher?',
          confirm: 'Einlösen',
          cancel: 'Abbrechen',
        },
      },
      custom: {
        warning_text: 'Der Gutschein ist nur einmal einlösbar.',
        redeem: 'aktivieren',
        redeeming: 'aktiviere ...',
        redeemed: 'Pass aktiviert',
        redeem_later: 'Später aktivieren',
        activate: 'Aktivieren',
        redeem_done:
          'Es tut uns leid, du hast diesen Coupon bereits eingelöst.',
      },
      treasurehunt: {
        available_shortly: 'AB <br> <b>{date}</b>',
        sold_out: 'Leider verpasst',
        user_sold_out: 'Geschlossen',
      },
    },
    halloween: {
      card_header: 'Happy Halloween',
      card_title: '13 Tage täglich neue Preise zu Halloween',
      card_subtitle: 'Halloweenspecial',
      rewards_title: 'Halloween-Prämien',
      raffle_title: 'Halloween-Gewinnspiele',
      quiz_title: 'Halloween-Quiz',
      soon_available: 'Bald ist es soweit!',
      back: 'Zurück zum Halloween Special',
    },
    advent: {
      card_header: 'Adventskalender',
      card_title: 'Entdecke täglich neue Preise im Adventskalender!',
      soon_available: 'Bald ist es soweit!',
      back: 'Zurück zum Adventskalender',
    },
    adventspecial: {
      card_header: 'Adventskalender-Aktion',
      card_title:
        'Bereit für den Advent? Gewinne täglich einen Adventskalender',
      soon_available: 'Bald ist es soweit!',
      back: 'Zurück zur Adventskalender-Aktion',
    },
    blacklaendleweek: {
      card_header: 'Black-Ländle-Week',
      card_title: 'Black-Ländle-Week: Täglich regionale Preise gewinnen!',
      back: 'Zurück zur Black-Ländle-Week',
    },
    newyear: {
      card_header: 'Vorsätze-Woche',
      card_title: 'Täglich neue Preise in der Vorsätze-Woche',
      card_subtitle: 'Neujahrs-Special',
      rewards_title: 'Neues Jahr - neue Prämien',
      raffle_title: 'Neujahrs-Gewinnspiele',
      quiz_title: 'Neujahrs-Quiz',
      back: 'Zurück zum Special',
    },
    friends: {
      title: 'Freunde',
      overview: {
        friend_info: 'Du hast einen Freund. | Du hast {count} Freunde.',
        show_all: 'Alle anzeigen',
      },
      unfriend: {
        title: 'Bist du sicher?',
        description:
          'Bist du sicher, dass du "{username}" als Freund/in entfernen möchtest?',
      },
      my_requests: 'Erhaltene Anfragen',
      my_friends: 'Meine Freunde',
      no_friends: 'Du hast noch keine Freunde hinzugefügt',
      no_friends_long:
        'Du hast noch keine Freunde hinzugefügt. Klicke hier, um andere Ländlepunkte-Spieler zu suchen.',
      invite_friends: 'Lade jetzt deine Freunde ein.',
      add_friends: 'Freunde hinzufügen',
      search_friends: 'Freunde suchen',
      service_offline:
        'Der Freunde-Service ist momentan leider nicht verfügbar. Bitte versuch es später wieder.',
    },
    notifications: {
      levelup: {
        title: 'Level Up',
        fallback: 'Du bist ein Level aufgestiegen! Glückwunsch.',
      },
      register: {
        title: 'Willkommen',
        text: 'Melde dich gleich an und genieße alle Vorteile von VOL.AT, mit einem Account kannst du auch deine gesammelten Ländlepunkte nicht verlieren.',
      },
      raffle: {
        button: 'Jetzt mitmachen',
        close: 'Alles klar!',
      },
      profile: {
        text: 'Jetzt dein Profil vervollständigen und zusätzliche Ländlepunkte sammeln.',
        btn: 'zum Profil',
      },
      badge: {
        fallback:
          'Herzlichen Glückwunsch! Du hast eine Auszeichnung erhalten!',
      },
    },
    voucher: {
      howto: {
        title: 'So funktioniert’s:',
        step1:
          '1. Akzeptiere die Berechtigungsanfrage um den Zugriff auf die Kamera zu erlauben.',
        step2: '2. Scanne den QR Code',
        step3:
          '3. Der Gutschein wird automatisch eingelöst. Falls der Code nicht erkannt werden konnte, prüfe die ersten zwei Schritte und versuche es erneut.',
      },
      success_title: 'Herzlichen Glückwunsch!',
      success_text: 'Mit diesem Gutschein hast du dir folgendes gesichert:',
      success_info_text:
        'Der Gutschein wird direkt auf deinem Ländlepunkte Konto gutgeschrieben. Löse jetzt deine Ländlepunkte gegen weitere Prämien oder exklusive Gewinnspiele ein. Deine erhaltenen Prämien findest du in deinem Profil.',
      error_title: 'Oh Nein!',
      link_button: 'Zum QR-Code Scanner',
      start_scanner: 'Scanner Starten',
      select_file: 'Foto hochladen',
      item_type: {
        raffle: 'Gewinnspielteilname',
        reward: 'Prämie',
      },
      errors: {
        empty:
          'Da ist wohl was schiefgelaufen, das tut uns leid. Versuche es später erneut.',
        already_redeemed: 'Dieser Code wurde bereits benutzt.',
        code_invalid: 'Dieser Code ist leider ungültig.',
        not_for_anonymous:
          'Um diesen Code einzulösen musst du angemeldet sein. Bitte melde dich an und versuche es dann erneut.',
        time_limit_reached:
          'Hoppla, wie es scheint hast du diesen Gutschein bereits eingelöst oder das Limit für heute erreicht.',
        camera_not_supported:
          'Zugriff auf die Kamera nicht möglich oder dein Gerät unterstützt dies nicht. Bitte wähle ein Foto aus.',
      },
    },
    onboarding: {
      default: {
        slide1: {
          welcome: 'Herzlich willkommen',
          text: 'Hier wirst du für deine Treue auf VOL.AT mit Ländlepunkten belohnt.',
          bottom_text: 'Diese kannst du gegen tolle Preise eintauschen!',
          skip: 'Überspringen',
        },
        slide2: {
          text: 'Jetzt anmelden oder registrieren und mitmachen!',
          top_text:
            'Sammle automatisch mit jeder Aktivität auf VOL.AT wertvolle Ländlepunkte.',
          bottom_text:
            'Und tausche deine Punkte in exklusive Sofortpreise oder Gewinnspielteilnahmen ein.',
          bottom_text_long:
            'Und tausche deine Punkte in exklusive Sofortpreise oder Gewinnspielteilnahmen ein. Außerdem nehmen alle neuen Spieler automatisch an einem unserer exklusiven Gewinnspiele teil.',
          loggedin_text_start:
            'Danke, dass du ein Teil der VOL.AT Community bist.',
          loggedin_text_second:
            '<br> Als Willkommensgeschenk bist du automatisch bei unserem exklusiven Gewinnspiel dabei.',
          loggedin_alt_text: 'Alles startklar?',
          loggedin_text_end: 'Viel Spaß beim Entdecken weiterer Prämien.',
          steps: {
            read: 'Artikel lesen',
            comment: 'Kommentieren',
            video: 'Videos anschauen',
          },
        },
        thanks: 'Wische nach links und erfahre mehr!',
        letsgo: 'Los gehts!',
      },
    },
    activities: {
      topactivities: 'Top-Aktivitäten',
      lastactivities: 'Letzte Aktivitäten',
      noactivity: 'Keine Aktivität',
    },
    badges: {
      title: 'Deine Auszeichnungen',
    },
    datepicker: {
      weekdays: ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'],
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      test: 'bla',
    },
    countdown: {
      title: 'Verlosung in',
      title_reward: 'Läuft noch',
      day: 'TAGE',
      hour: 'STD',
      minute: 'MIN',
      second: 'SEK',
    },
    sharing: {
      fb: 'Teilen!',
    },
  },
  loading: 'Einen Moment bitte',
  buttons: {
    redeem_now: 'Jetzt einlösen',
    dailysurprise: 'Überraschung des Tages',
    explore: 'Prämien entdecken',
    retry: 'Erneut versuchen',
    register_now: 'Jetzt Anmelden',
    ok: 'Ok',
    yes: 'Ja',
    abort: 'Abbrechen',
    thanks: 'Danke',
    no_thanks: 'Nein Danke',
    send: 'Senden',
    back: 'Zurück',
  },
  roadshow: {
    title: 'Ländlepunkte Roadshow',
    info: 'Das Ländlepunkte Glücksrad ist am 20.05. im Messepark! Komme zu unserem Stand, drehe das Glücksrad und gewinne tolle Preise. Du musst lediglich angemeldet sein und den QR Code scannen lassen.',
    anonymous: 'Du musst angemeldet sein um am Gewinnspiel teilzunehmen',
    events_title: 'Die Termine',
    events: [
      {
        date: '21.02.2020 - 22.02.2020',
        location: 'GWL Bregenz',
      },
      {
        date: '28.02.2020 - 29.02.2020',
        location: 'Zimbapark Bürs',
      },
      {
        date: '07.03.2020',
        location: 'Stadtmarkt Dornbirn',
      },
      {
        date: '24.04.2020 - 25.04.2020',
        location: 'Das Buch, Messepark Dornbirn',
      },
    ],
  },
  deliveryInformation: {
    title: 'Wichtige Information',
    text: 'Gesundheit geht vor - deshalb werden aktuell keine Prämien versendet.',
  },
  widget: {
    main: {
      title: 'Täglich Neuigkeiten lesen und dabei <b>Ländlepunkte</b> sammeln.',
    },
    prizewheel: {
      title: 'Das Ländlepunkte Glücksrad',
      subtitle: 'Dreh am Glücksrad und gewinne tolle Preise',
    },
    quiz: {
      title: 'Zeit für ein Quiz',
    },
    special: {
      button: 'Jetzt mitmachen',
    },
    reward: {
      description: '<b>Jetzt Ländlepunkte einlösen und die Prämie sichern</b>',
    },
    user: {
      title: 'Dein Punktestand',
      points_available: 'aktueller Punktestand:',
      retire_info: 'Diese Punkte verfallen bald, wenn du sie nicht einlöst:',
      date: '{day}.{month}.{year}',
      prompt: 'Diese Prämie könnte dir gefallen!',
    },
    toLayer: 'Zu den Ländlepunkten',
  },
  voucher: {
    errors: {
      already_redeemed:
        'Du hast die Prämie bereits eingelöst. Freue dich auf weitere Prämien im Shop.',
      code_invalid: 'Dieser Code ist nicht gültig.',
    },
  },
}
