import { PiniaPlugin } from 'pinia'

const plugin: PiniaPlugin = ({ store }) => {
  store.$onAction(({ onError }) => {
    onError((error) => {
      console.log(error)
    })
  })
}

export default plugin;
