import { defineStore } from 'pinia'

type MeState = {
  display_type: string
  collector_token: string
  token: string
  hashed_collector_token: string
  hash: string
  anonymous: boolean
  points: number
  points_retirement: any
  currency: { basename: string; media: any }
  level: any
  next_level: any
  badges: any
  forced_currency: any
  personal_data: any
  personal_data_fetched: any
  personal_data_error: any
  meta: any
  activities: {
    top: any[]
    last: any[]
  }
  dailyReward: {
    items: any[]
    won: any
  }
  dailyRewardGotToday: boolean
  dailyRewardFetched: boolean
  lang: string
  username: string
  purAbo: boolean
}
export const useMeStore = defineStore('me', {
  state: (): MeState => ({
    display_type: 'overview',
    collector_token: '',
    token: '',
    hashed_collector_token: '',
    hash: '',
    anonymous: true,
    points: 0,
    points_retirement: {},
    currency: { basename: '', media: [] },
    level: null,
    next_level: null,
    badges: [],
    forced_currency: null,
    personal_data: {},
    personal_data_fetched: false,
    personal_data_error: false,
    meta: null,
    activities: {
      top: [],
      last: [],
    },
    dailyReward: {
      items: [],
      won: {},
    },
    dailyRewardGotToday: false,
    dailyRewardFetched: false,
    lang: 'de',
    username: '',
    purAbo: false,
  }),

  actions: {
    set_collector_token(v: any) {
      this.collector_token = v
      const rand = Math.round(Math.random() * (9999 - 1000) + 1000)
      this.hashed_collector_token = `${rand}${v}`
    },
    top_activities(v: any) {
      this.activities.top = v
    },
    last_activities(v: any) {
      this.activities.last = v
    },
    email(v: any) {
      this.personal_data.email = v
    },
    SET_DAILYREWARD_FETCHED(v: any) {
      this.dailyRewardFetched = v
    },
    SET_DAILYREWARD_GOT_TODAY(v: any) {
      this.dailyRewardGotToday = v
    },
    FETCH_DAILYREWARDITEMS_SUCCESS(v: any) {
      this.dailyReward.items = v
    },
    FETCH_DAILYREWARD_SUCCESS(v: any) {
      this.dailyReward.won = v
    },
    RESET_DAILYREWARD_FETCHED() {
      this.dailyReward.won = {}
      this.dailyRewardFetched = false
    },
    async fetchCollector() {
      if (this.collector_token === '' || this.collector_token === undefined) {
        return
      }

      const mainStore = useMainStore()

      try {
        const data = await useApi().$get(`/me`)

        if (data.collector_token === this.collector_token) {
          this.lang = data.lang
          this.points = data.points_available
          this.anonymous = data.anonymous
          this.username = data.username
          this.level = data.level
          this.next_level = data.next_level
          this.badges = data.badges
          this.meta = data.meta
          this.currency = data.currency
          mainStore.uri_scheme = data.customer.uri_scheme
          this.points_retirement = data.points_retirement
          if (data.daily_reward !== null) {
            this.FETCH_DAILYREWARD_SUCCESS(data.daily_reward)
            this.SET_DAILYREWARD_GOT_TODAY(true)
          }
          // await this.afterCollectorLoaded()
          mainStore.AppState = 'ready'
        } else {
          mainStore.AppState = 'error'
        }
      } catch (e: any) {
        console.log(e)
        // useBugsnag().notify(e)
        mainStore.AppState = 'error'
      }
    },

    async afterCollectorLoaded() {
      const promises = []
      // const route = useRoute().path

      // if (route === '/me/rewards') {
      promises.push(this.logVisit())
      // }

      const store = useSpecialStore()
      promises.push(store.fetch())

      await Promise.all(promises)
    },

    triggerStep({ step, data }: any) {
      useApi().$post(`/actions`, {
        data,
        step,
      })
    },

    logVisit() {
      this.triggerStep({ step: 'layerVisit', data: [] })
    },

    async fetchPersonalData() {
      if (this.display_type !== 'overview') {
        return
      }
      if (!this.profileAvailable) {
        if (!this.anonymous) {
          useBugsnag().notify('Error on getting Hash and Token')
        }
        return
      }

      await useDataService().fetchPersonalData()
    },

    async updatePersonalData(personalData: any) {
      if (!this.profileAvailable) {
        if (!this.anonymous) {
          useBugsnag().notify('Error on getting Hash and Token')
        }
        return
      }

      const personalDataTranslated = {
        firstname: personalData.firstname,
        lastname: personalData.lastname,
        email: personalData.email,
        birthday: personalData.birthday,
        phone: personalData.telephone,
        street: personalData.street,
        street_no: personalData.street_nr,
        city: personalData.city,
        zip_code: personalData.postcode,
        country: personalData.country,
        privacy: personalData.privacy,
      }

      const mainStore = useMainStore()

      try {
        await useApi().$post(`${mainStore.sso_endpoint}update/user`, {
          h: this.hash,
          t: this.token,
          login: this.personal_data.username,
          ...personalDataTranslated,
        })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async updatePersonalDataService(personalData: any) {
      delete personalData.accepted_agbs
      delete personalData.mobile_phone
      delete personalData.privacy
      delete personalData.salutation
      delete personalData.title
      delete personalData.crypto

      personalData.postcode = personalData.zip_code
      delete personalData.zip_code
      personalData.telephone = personalData.phone
      delete personalData.phone
      personalData.street_nr = personalData.street_no
      delete personalData.street_no

      if (personalData.gender) {
        if (personalData.gender === '1') personalData.gender = 'm'
        if (personalData.gender === '2') personalData.gender = 'f'
      } else {
        personalData.gender = ''
      }

      try {
        await useApi().$post(`/me/personalData`, {
          data: {
            ...personalData,
          },
        })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async uploadAvatar({ url, formData }: any) {
      try {
        formData.append('hash', this.hash)
        formData.append('token', this.token)

        await $fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            accept: 'application/json',
          },
        })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async deleteAvatar(url: any) {
      try {
        await $fetch(url, {
          method: 'DELETE',
          data: {
            hash: this.hash,
            token: this.token,
          },
          headers: {
            accept: 'application/json',
          },
        })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchActivityData() {
      try {
        const data = await useApi().$get(`/me/activities`)

        this.top_activities(data.top)
        this.last_activities(data.last)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async sawOnboarding(type: any) {
      try {
        await useApi().$post(`/me/sawOnboard`, {
          type,
        })

        this.fetchCollector()
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async optOut(reason: any) {
      try {
        await useApi().$post(`/me/optOut`, {
          reason,
        })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchDailyRewardItems() {
      try {
        const data = await useApi().$get(`/dailyRewards`)

        this.FETCH_DAILYREWARDITEMS_SUCCESS(data)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchDailyReward() {
      try {
        const data = await useApi().$get(`/me/dailyreward`)

        this.FETCH_DAILYREWARD_SUCCESS(data)
        this.SET_DAILYREWARD_FETCHED(true)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },
    setDailyrewardGotToday() {
      this.SET_DAILYREWARD_GOT_TODAY(true)
    },
  },

  getters: {
    getMeta(state) {
      return (key: any) => {
        return state.meta.filter((item: any) => {
          return item.key === key
        })
      }
    },
    personalData(state) {
      return (key: any) => {
        // eslint-disable-line arrow-body-style
        return state.personal_data !== null ? state.personal_data[key] : ''
      }
    },
    personalDataAll: (state) => state.personal_data,
    personalDataFetched: (state) => state.personal_data_fetched,
    personalDataError: (state) => state.personal_data_error,
    supportToken(state) {
      return state.collector_token !== undefined && state.collector_token !== ''
        ? `c${String(state.collector_token).slice(
            String(state.collector_token).length - 8,
            String(state.collector_token).length,
          )}`
        : 'c000'
    },
    currencyIcon: (state) =>
      get(['currency', 'media', 0, 'url'], state) || null,
    badgeById: (state) => (key: any) =>
      state.badges.find((badge: any) => badge.id === key) || null,
    currencyMedia: (state) => state.currency.media,
    dailyRewardWon: (state) => state.dailyReward.won,
    dailyRewardItems: (state) => state.dailyReward.items,
    currencyNameTranslated: (state) => (t: any) =>
      t(`currencies.${state.currency.basename}`) || '',
    profileAvailable: (state) => useDataService().isProfileAvailable(state),
    privacy: (state) =>
      state.personal_data.privacy !== undefined
        ? state.personal_data.privacy
        : false,
    personalDataTranslated: (state) => {
      return {
        firstname: state.personal_data.firstname,
        lastname: state.personal_data.lastname,
        email: state.personal_data.email,
        telephone: state.personal_data.phone,
        birthday: state.personal_data.birthday,
        street: state.personal_data.street,
        street_nr: state.personal_data.street_no,
        city: state.personal_data.city,
        postcode: state.personal_data.zip_code,
        country: state.personal_data.country,
        crypto: state.personal_data.crypto,
      }
    },
  },
})

const get = (path: Array<string | number>, object: any) =>
  path.reduce(
    (objectAccumulator, pathString) =>
      objectAccumulator && objectAccumulator[pathString]
        ? objectAccumulator[pathString]
        : null,
    object,
  )
