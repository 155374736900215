<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()
const $config = useRuntimeConfig().public

const mainStore = useMainStore()
const meStore = useMeStore()
const store = useSpecialStore()

onMounted(() => {
  if (import.meta.client) {
    window.addEventListener('message', (event) => {
      if (event.data === 'tee-reset-to-home') {
        router.push('/me/rewards')
      }
    })
  }
})

useAsyncData(async () => {
  await mainStore.nuxtServerInit({
    route,
    $config,
  })

  await meStore.fetchCollector()

  await store.fetch()

  return true
})
</script>
