<template>
  <div class="error-container">
    <img src="~/assets/img/currency_icon.png" class="icon" />
    <p class="currency">Ländlepunkte</p>
    <p class="text">Ein unerwarteter Fehler ist aufgetreten</p>
  </div>
</template>

<script>
export default {
  layout: 'static',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  @apply text-center pt-10;
}

.icon {
  @apply mx-auto h-24 mt-2;
}

.currency {
  @apply text-4xl font-theme-title text-theme-primary;
}

.text {
  @apply text-xl;
}
</style>
