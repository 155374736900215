import { defineStore } from 'pinia'
// import Vue from 'vue'

type RewardState = {
  rewards_raw: {
    current_page: number
    last_page: number
    data: any[]
  }
  rewards: {
    current_page: number
    data: any[]
    fetch_pending: boolean
  }
  singleReward: {
    id: number
    data: any
  }
  categories: any[]
  categories_filtered: any[]
  fetched_categories: boolean
  reward_categories: any
  reward_categories_filtered: any
  reward_items: any[]
  myRewards: any[]
  myRewardsFetched: boolean
  myRewardsHasMore: boolean
  myActiveRewards: any[]
  myActiveRewardsFetched: boolean
  myTopActiveRewards: any[]
  myTopActiveRewardsFetched: boolean
  myRewardsNextPage: number
  myCurrentRaffles: any[]
  myCurrentRafflesFetched: boolean
  myTopRaffles: any[]
  myTopRafflesFetched: boolean
  myCompletedRaffles: any[]
  myCompletedRafflesFetched: boolean
  myCompletedRafflesHasMore: boolean
  myCompletedRafflesNextPage: number
}
export const useRewardsStore = defineStore('rewards', {
  state: (): RewardState => ({
    rewards_raw: {
      current_page: 0,
      last_page: 1,
      data: [],
    },
    rewards: {
      current_page: 0,
      data: [],
      fetch_pending: false,
    },
    singleReward: {
      id: 0,
      data: {},
    },
    categories: [],
    categories_filtered: [],
    fetched_categories: false,
    reward_categories: {},
    reward_categories_filtered: {},
    reward_items: [],
    myRewards: [],
    myRewardsFetched: false,
    myRewardsHasMore: false,
    myActiveRewards: [],
    myActiveRewardsFetched: false,
    myTopActiveRewards: [],
    myTopActiveRewardsFetched: false,
    myRewardsNextPage: 1,
    myCurrentRaffles: [],
    myCurrentRafflesFetched: false,
    myTopRaffles: [],
    myTopRafflesFetched: false,
    myCompletedRaffles: [],
    myCompletedRafflesFetched: false,
    myCompletedRafflesHasMore: false,
    myCompletedRafflesNextPage: 1,
  }),

  actions: {
    FETCH_REWARDS_SUCCESS(v: any) {
      this.rewards_raw = v
      if (this.rewards.current_page < v.current_page) {
        this.rewards.data.push(...v.data)
        this.rewards.current_page = v.current_page
      }
    },
    FETCH_REWARD_CATEGORY_SUCCESS({ data, categoryId }: any) {
      const id = this.categories.findIndex((c) => c.id === categoryId)
      if (this.categories[id]?.current_page < data.meta.current_page) {
        let d = data.data
        if (this.reward_categories[categoryId] !== undefined) {
          d = [...this.reward_categories[categoryId].data, ...data.data]
        }

        this.reward_categories[categoryId] = {
          data: d,
          last_page: data.meta.last_page,
        }

        this.categories[id].current_page = data.meta.current_page
      }
    },
    FETCH_FILTERED_REWARD_CATEGORY_SUCCESS({ data, categoryId }: any) {
      const id = this.categories_filtered.findIndex((c) => c.id === categoryId)
      if (this.categories_filtered[id]?.current_page < data.meta.current_page) {
        let d = data.data
        if (this.reward_categories_filtered[categoryId] !== undefined) {
          d = [
            ...this.reward_categories_filtered[categoryId].data,
            ...data.data,
          ]
        }

        this.reward_categories_filtered[categoryId] = {
          data: d,
          last_page: data.meta.last_page,
        }

        this.categories_filtered[id].current_page = data.meta.current_page
      }
    },
    FETCHING_REWARD_CATEGORY(data: any) {
      const id = this.categories.findIndex((c) => c.id === data.categoryId)
      if (id > -1) {
        let cat = this.categories[id] // eslint-disable-line
        cat.fetch_pending = data.fetching
        this.categories[id] = cat
      }
    },
    FETCHING_FILTERED_REWARD_CATEGORY(data: any) {
      const id = this.categories_filtered.findIndex(
        (c) => c.id === data.categoryId,
      )
      if (id > -1) {
        let cat = this.categories_filtered[id] // eslint-disable-line
        cat.fetch_pending = data.fetching
        this.categories_filtered[id] = cat
      }
    },
    FETCH_MY_REWARDS_SUCCESS(data: any) {
      this.myRewards = data
      this.myRewardsFetched = true
    },
    SET_MY_REWARDS_PAGINATE_SUCCESS(data: any) {
      this.myRewards = this.myRewards.concat(data)
      this.myRewardsFetched = true
    },
    SET_HAS_MORE_REWARDS(data: any) {
      this.myRewardsHasMore = data
    },
    SET_MY_REWARDS_FETCHED(data: any) {
      this.myRewardsFetched = data
    },
    SET_MY_ACTIVE_REWARDS(data: any) {
      this.myActiveRewards = data
      this.myActiveRewardsFetched = true
    },
    SET_MY_TOP_ACTIVE_REWARDS(data: any) {
      this.myTopActiveRewards = data
      this.myTopActiveRewardsFetched = true
    },
    FETCH_REWARD_ITEM_SUCCESS({ data, id }: any) {
      this.reward_items[id] = data
    },
    FETCH_MY_CURRENT_RAFFLES_SUCCESS(data: any) {
      this.myCurrentRaffles = data
      this.myCurrentRafflesFetched = true
    },
    SET_MY_TOP_RAFFLES(data: any) {
      this.myTopRaffles = data
      this.myTopRafflesFetched = true
    },
    FETCH_MY_COMPLETED_RAFFLES_SUCCESS(data: any) {
      this.myCompletedRaffles = this.myCompletedRaffles.concat(data)
      this.myCompletedRafflesFetched = true
    },
    SET_COMPLETED_RAFFLES_FETCHED(data: any) {
      this.myCompletedRafflesFetched = data
    },
    SET_HAS_MORE_COMPLETED_RAFFLES(data: any) {
      this.myCompletedRafflesHasMore = data
    },
    SET_NEXT_PAGE_COMPLETED_RAFFLES(data: any) {
      this.myCompletedRafflesNextPage = data
    },
    FETCH_CATEGORIES_SUCCESS(data: any) {
      const newData = data.map((item: any) => ({
        ...item,
        current_page: 0,
        fetch_pending: false,
      }))
      this.categories = newData
      this.fetched_categories = true
    },
    FETCH_CATEGORIES_FILTERED_SUCCESS(data: any) {
      const newData = data.map((item: any) => ({
        ...item,
        current_page: 0,
        fetch_pending: false,
      }))
      this.categories_filtered = newData
      this.fetched_categories = true
    },
    FETCH_REWARD_SINGLE_SUCCESS(data: any) {
      this.singleReward.id = data.id
      this.singleReward.data = data
    },
    FETCH_REWARD_SINGLE_NOTFOUND(id: number) {
      this.singleReward.id = id
      this.singleReward.data = {}
      this.singleReward.data.error = 404
    },
    REMOVE_REWARD_FROM_MY_REWARDS(v: any) {
      this.myRewards.splice(v, 1)
    },
    UPDATE_PREMIUM_PASS_STATUS(id: any) {
      const index = this.myRewards.findIndex((c) => c.id === id)
      if (index > -1) {
        const reward = this.myRewards[index]
        reward.premium_expired = false
        this.myRewards[index] = reward
      }
    },
    async fetchSingleReward(rewardId: number) {
      try {
        const data = await useApi().$get(`/v2/rewards/${rewardId}`)

        this.FETCH_REWARD_SINGLE_SUCCESS(data)
      } catch (e: any) {
        if (e.statusCode === 404) {
          this.FETCH_REWARD_SINGLE_NOTFOUND(rewardId)
        } else {
          useBugsnag().notify(e)
        }
      }
    },

    async fetchRewardCategory({ categoryId, page }: any) {
      if (categoryId === 0 || isNaN(categoryId)) {
        return
      }

      if (this.isCategoryFetching(categoryId)) {
        return
      }

      this.FETCHING_REWARD_CATEGORY({ categoryId, fetching: true })

      try {
        const data = await useApi().$get(`/v2/rewards/category/${categoryId}`, {
          params: {
            page,
          },
        })

        this.FETCH_REWARD_CATEGORY_SUCCESS({ data, categoryId })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
      this.FETCHING_REWARD_CATEGORY({ categoryId, fetching: false })
    },

    async fetchEntireRewardCategory({
      categoryId,
      page,
      overwrite_per_page,
    }: any) {
      if (categoryId === 0 || isNaN(categoryId)) {
        return
      }

      if (this.isCategoryFetching(categoryId)) {
        return
      }

      this.FETCHING_REWARD_CATEGORY({ categoryId, fetching: true })

      try {
        const data = await useApi().$get(`/v2/rewards/category/${categoryId}`, {
          params: {
            page,
            overwrite_per_page,
          },
        })

        this.FETCH_REWARD_CATEGORY_SUCCESS({ data, categoryId })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
      this.FETCHING_REWARD_CATEGORY({ categoryId, fetching: false })
    },

    async fetchNextRewardCategoryPage(categoryId: any) {
      const cat = this.categories.find((c) => c.id === categoryId)
      const lastPage =
        this.reward_categories[categoryId] !== undefined
          ? this.reward_categories[categoryId].last_page
          : 1

      if (cat.current_page < lastPage) {
        await this.fetchRewardCategory({
          categoryId,
          page: cat.current_page + 1,
        })
      }
    },

    async fetchNextFilteredRewardCategoryPage(categoryId: any) {
      const cat = this.categories_filtered.find((c) => c.id === categoryId)
      const lastPage =
        this.reward_categories_filtered[categoryId] !== undefined
          ? this.reward_categories_filtered[categoryId].last_page
          : 1

      if (cat.current_page < lastPage) {
        await this.fetchFilteredRewardCategory({
          categoryId,
          page: cat.current_page + 1,
        })
      }
    },

    async fetchFirstRewardCategoryPage(categoryId: any) {
      const cat = this.reward_categories[categoryId]
      if (cat === undefined) {
        await this.fetchRewardCategory({
          categoryId,
          page: 1,
        })
      }
    },

    async fetchEntireRewardCategoryPage(categoryId: any) {
      const cat = this.reward_categories[categoryId]
      if (cat === undefined) {
        await this.fetchEntireRewardCategory({
          categoryId,
          page: 1,
          overwrite_per_page: 50,
        })
      }
    },

    async fetchFirstFilteredRewardCategoryPage(categoryId: any) {
      const cat = this.reward_categories_filtered[categoryId]
      if (cat === undefined) {
        await this.fetchFilteredRewardCategory({
          categoryId,
          page: 1,
        })
      }
    },

    async fetchFilteredRewardCategory({ categoryId, page, filter }: any) {
      if (categoryId === 0 || isNaN(categoryId)) {
        return
      }

      if (this.isFilteredCategoryFetching(categoryId)) {
        return
      }

      this.FETCHING_FILTERED_REWARD_CATEGORY({ categoryId, fetching: true })

      try {
        const data = await useApi().$get(`/v2/rewards/category/${categoryId}`, {
          params: {
            page,
            overwrite_per_page: 30,
            filter: 'subscriber',
          },
        })

        this.FETCH_FILTERED_REWARD_CATEGORY_SUCCESS({ data, categoryId })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
      this.FETCHING_FILTERED_REWARD_CATEGORY({ categoryId, fetching: false })
    },

    async fetchCategories(firstFetch = true) {
      if (this.fetched_categories) {
        return
      }

      try {
        const data = await useApi().$get(`/rewards/de/categories`)

        this.FETCH_CATEGORIES_SUCCESS(data)
        this.FETCH_CATEGORIES_FILTERED_SUCCESS(data)

        if (firstFetch) {
          const promises = this.categories.map((category) =>
            this.fetchFirstRewardCategoryPage(category.id),
          )

          await Promise.all(promises)
        }
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchMyRewards() {
      const page = this.myRewardsNextPage

      try {
        const data = await useApi().$get(`/v2/me/rewards/items`, {
          params: {
            page,
          },
        })

        this.SET_MY_REWARDS_PAGINATE_SUCCESS(data.data)
        this.SET_HAS_MORE_REWARDS(data.links.next !== null)
        if (data.meta.current_page !== data.meta.last_page) {
          this.myRewardsNextPage = data.meta.current_page + 1
        }
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchMyActiveRewards() {
      try {
        const data = await useApi().$get(`/v2/me/rewards/myActive`)

        this.SET_MY_ACTIVE_REWARDS(data)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchMyTopActiveRewards() {
      try {
        const data = await useApi().$get(`/v2/me/rewards/myTopActive`)

        this.SET_MY_TOP_ACTIVE_REWARDS(data)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchRewardItems(id: any) {
      try {
        const data = await useApi().$get(`/me/rewards/${id}/items`)

        this.FETCH_REWARD_ITEM_SUCCESS({ data, id })
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchMyCurrentRaffles() {
      try {
        const data = await useApi().$get(`/v2/me/rewards/currentRaffles`)

        this.FETCH_MY_CURRENT_RAFFLES_SUCCESS(data)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchMyTopRaffles() {
      try {
        const data = await useApi().$get(`/v2/me/rewards/myTopRaffles`)

        this.SET_MY_TOP_RAFFLES(data)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async fetchMyCompletedRaffles() {
      const page = this.myCompletedRafflesNextPage
      try {
        const data = await useApi().$get(`/v2/me/rewards/completedRaffles`, {
          params: {
            page,
          },
        })

        this.FETCH_MY_COMPLETED_RAFFLES_SUCCESS(data.data)
        this.SET_HAS_MORE_COMPLETED_RAFFLES(data.links.next !== null)
        this.SET_NEXT_PAGE_COMPLETED_RAFFLES(data.meta.current_page + 1)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async releaseRewardReservation(id: any) {
      try {
        await useApi().$post(`/me/rewards/release/${id}`)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async cancelRewardOrder(id: any) {
      try {
        await useApi().$post(`/v2/me/rewards/cancel/${id}`)

        const index = this.myRewards.findIndex((c) => c.id === id)
        if (index > -1) {
          this.REMOVE_REWARD_FROM_MY_REWARDS(index)
        }
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },

    async activatePremiumPass(id: any) {
      try {
        await useApi().$post(`/v2/me/rewards/activate/${id}`)

        this.UPDATE_PREMIUM_PASS_STATUS(id)
      } catch (e: any) {
        useBugsnag().notify(e)
      }
    },
  },

  getters: {
    categoryRewards: (state) => state.reward_categories,

    getCategoryRewards: (state) => (id: number) => state.reward_categories[id],

    getFilteredCategoryRewards: (state) => (id: number) =>
      state.reward_categories_filtered[id],

    getMyTopRewards: (state) => state.myTopActiveRewards,

    getMyTopRewardsFetched: (state) => state.myTopActiveRewardsFetched,

    getMyTopRaffles: (state) => state.myTopRaffles,

    getMyTopRafflesFetched: (state) => state.myTopRafflesFetched,

    getCurrentRaffles: (state) => state.myCurrentRaffles,

    getCurrentRafflesFetched: (state) => state.myCurrentRafflesFetched,

    getCompletedRaffles: (state) => state.myCompletedRaffles,

    getCompletedRafflesFetched: (state) => state.myCompletedRafflesFetched,

    getCompletedRafflesHasMore: (state) => state.myCompletedRafflesHasMore,

    isCategoryFetched: (state) => (id: number) =>
      state.reward_categories[id] !== undefined,

    isCategoryFetching: (state) => (id: number) => {
      const index = state.categories.findIndex((category) => category.id === id)

      if (index > -1) {
        return state.categories[index].fetch_pending
      }

      return false
    },

    isFilteredCategoryFetching: (state) => (id: number) => {
      const index = state.categories_filtered.findIndex(
        (category) => category.id === id,
      )

      if (index > -1) {
        return state.categories_filtered[index].fetch_pending
      }

      return false
    },

    getCurrentCategoryPage: (state) => (id: number) => {
      const index = state.categories.findIndex((category) => category.id === id)
      if (index > -1) {
        return state.categories[index].current_page
      }
      return 0
    },

    rewardItems: (state) => state.reward_items,

    getReward: (state) => (id: number) =>
      state.rewards.data.find((item) => item.id === id) || {},

    getSingleReward: (state) => (id: number) =>
      state.singleReward.id === id ? state.singleReward.data : {},

    getRewardItems: (state) => (id: number) => state.reward_items[id],
  },
})
