import { createI18n } from 'vue-i18n'

const loadedLanguages = ['de']

export default defineNuxtPlugin(async ({ vueApp, $pinia }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  const i18n = createI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: { de },
  })

  vueApp.use(i18n)

  const store = useMeStore($pinia)

  const lang = store.lang

  if (lang !== i18n.global.locale) {
    await loadLanguageAsync(lang, vueApp)
  }

  // store.$subscribe(async (mutation, state) => {
  //   if (mutation.type === 'me/lang') {
  //     await loadLanguageAsync(mutation.payload, vueApp)
  //   }
  // })
})

function loadLanguageAsync(lang: string, app: any) {
  // If the same language
  if (app.i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang, app))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang, app))
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ /* @vite-ignore */ `~/locales/${lang}.js`
  ).then((messages) => {
    app.i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang, app)
  })
}

function setI18nLanguage(lang: string, app: any) {
  app.i18n.locale = lang
  return lang
}
