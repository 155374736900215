export function useDataService() {
  async function fetchPersonalData() {
    const mainStore = useMainStore()
    const meStore = useMeStore()

    try {
      const data = await useApi().$get(`${mainStore.sso_endpoint}user`, {
        params: {
          h: meStore.$state.hash,
          t: meStore.$state.token,
        },
      })
      if (
        data?.error_text === 'Benutzer ist bzgl. des Mandanten nicht bekannt'
      ) {
        meStore.personal_data_error = true
        useBugsnag().notify(
          'SSO Profile was not available. Please check your hash and token.',
        )
      } else {
        const dsData = await useApi().$get(`/me/personalData`)
        data.username = dsData.username
        data.crypto = dsData.crypto

        meStore.personal_data = data
        meStore.personal_data_fetched = true
      }
    } catch (e: any) {
      useBugsnag().notify(e)
    }
  }

  return {
    fetchPersonalData,
    isProfileAvailable: (state: any) => state.hash !== '' && state.token !== ''
  }
}
