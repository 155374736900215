import revive_payload_client_Hjmf4WeR2N from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rpN6vpLoWw from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_rZ2LG0wREi from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AP71DG2EB3 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_client_DJHp3buL5r from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/modules/tracking/runtime/plugin.client.ts";
import chunk_reload_client_Wpj0cDp0U1 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_VfGcjrvSkj from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/plugins/i18n.ts";
import pinia_48xmdi2HHl from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/plugins/pinia.ts";
import swiper_eteSCvuYkd from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/plugins/swiper.ts";
import v_mask_dMN5UXPVua from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/plugins/v-mask.ts";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  payload_client_rpN6vpLoWw,
  check_outdated_build_client_I8AhPB5cLY,
  plugin_vue3_rZ2LG0wREi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  plugin_AP71DG2EB3,
  plugin_client_DJHp3buL5r,
  chunk_reload_client_Wpj0cDp0U1,
  i18n_VfGcjrvSkj,
  pinia_48xmdi2HHl,
  swiper_eteSCvuYkd,
  v_mask_dMN5UXPVua
]