import { defineStore } from 'pinia'
import { useMeStore } from '~/store/me'

type MainState = {
  environment: string | null
  site_url: string | null
  api_key: string
  platform: string
  frontend_url: string
  uri_scheme: string
  AppState: string
  project: string
  featured_reward_category: number
  featured_raffle_category: number
  special_category: number
  special_post_category: number
  special_raffle_category: number
  featured_fund_category: number
  language: string
  meta_title: string
  meta_description: string
  from_url: string
  entries: any
  avatar_api: string
  sso_endpoint: string
  uri_regex: RegExp
  steps: any
  fb_app_id: string
}
export const useMainStore = defineStore('main', {
  state: (): MainState => ({
    environment: null,
    site_url: null,
    api_key: '',
    platform: '',
    frontend_url: '',
    uri_scheme: '',
    AppState: 'loading',
    project: 'laendlepunkte',
    featured_reward_category: 0,
    featured_raffle_category: 0,
    special_category: 0,
    special_post_category: 0,
    special_raffle_category: 0,
    featured_fund_category: 0,
    language: 'de',
    meta_title: '',
    meta_description: '',
    from_url: '/',
    entries: {},
    avatar_api: '',
    sso_endpoint: '/sso/',
    uri_regex: new RegExp(/volat:\/\/[a-z]*\/(.*)/g),
    steps: {},
    fb_app_id: '119571164772886'
  }),

  actions: {
    SET_META(v: any) {
      this.meta_title = v.title
      this.meta_description = v.description
    },
    SET_FROM_URL(v: any) {
      this.from_url = v
    },
    SET_ENTRIES(v: any) {
      this.entries = v
    },
    async nuxtServerInit({ route, $config }: any) {
      const Settings = $config
      this.environment = Settings.environment
      this.site_url = Settings.site_url
      this.api_key = Settings.api_key
      this.frontend_url = Settings.frontend_url
      this.featured_reward_category = parseInt(
        Settings.featured_reward_category
      )
      this.featured_raffle_category = parseInt(
        Settings.featured_raffle_category
      )
      this.special_category = parseInt(Settings.special_category)
      this.special_post_category = parseInt(Settings.special_post_category)
      this.special_raffle_category = parseInt(Settings.special_raffle_category)
      this.featured_fund_category = parseInt(Settings.featured_fund_category)
      this.uri_regex = new RegExp(Settings.uri_regex)
      this.steps = Settings.steps

      this.SET_META({
        title: Settings.meta_title,
        description: Settings.meta_description
      })

      this.SET_ENTRIES(Settings.entries)

      const meStore = useMeStore()

      meStore.set_collector_token(route.query.collector_token)

      if (route.query.hash && route.query.token) {
        meStore.hash = route.query.hash
        meStore.token = route.query.token
      }

      if (route.query.is_pur_abo === 'true') {
        meStore.purAbo = true
      }

      if (route.query.email) {
        meStore.email(route.query.email)
      }

      if (route.query.platform) {
        this.platform = route.query.platform
      }

      this.avatar_api = Settings.avatar_api
    },
    openDeeplink(deeplink: any) {
      if (this.platform === 'desktop' || this.platform === 'mobileweb') {
        const url = `${this.uri_scheme}deeplink$${deeplink}`
        window.parent.postMessage(url, this.frontend_url)
      } else {
        window.location.href = `${this.uri_scheme}tee/webview?entry=${deeplink}`
      }
    },
    openExternalLink(externalLink: any) {
      let url = externalLink
      if (this.platform !== 'mobileweb' && this.platform !== 'desktop') {
        if (String(url).startsWith(`${this.uri_scheme}detail/`)) {
          url = `${this.uri_scheme}mobilenews/${String(url).substring(
            8,
            url.length
          )}`
        } else {
          url = `${this.uri_scheme}open-link$${externalLink}`
        }
        window.location.href = url
      } else {

        const regex = this.uri_regex
        const match = regex.exec(url)
        url = `${this.uri_scheme}open-link-blank$`

        if (match !== null && match.length > 1) {
          if (String(externalLink).startsWith(`${this.uri_scheme}detail/`)) {
            url += `${this.frontend_url}/detail/`
          }
          url += match[1]
        } else {
          url += externalLink
        }
        window.parent.postMessage(url, this.frontend_url)
      }
    },
    reloadApp(additionalQueries = '') {
      const meStore = useMeStore()
      const ctoken =
        meStore.collector_token !== undefined ? meStore.collector_token : ''
      const platform = this.platform
      const hash = meStore.hash
      const token = meStore.token

      const url = `${window.location.origin}/me/rewards?collector_token=${ctoken}&platform=${platform}&hash=${hash}&token=${token}${additionalQueries}`
      window.location.href = url
    }
  },

  getters: {
    shareUrl: (state) => (url: any) =>
      encodeURI(window.location.host + '/open?entry=' + encodeURI(url)),
    isApp: (state) => state.platform === 'android' || state.platform === 'ios'
  }
})
