import { defineStore } from 'pinia'

type Content = any

type MediaConversion = {
  id: number
  url: string
  conversions: {
    small: string
    default: string
  }
}[]

type Special = {
  id: number
  slug: string
  title: Record<string, string>
  card_title: Record<string, string>
  card_description: Record<string, string>
  widget_title: Record<string, string>
  widget_description: Record<string, string>
  available_from: string
  available_to: string
  menu_link: Record<string, string> | null
  media: {
    menu_icon: MediaConversion
    menu_icon_active: MediaConversion
    page_icon: MediaConversion
    header: MediaConversion
    card: MediaConversion
    widget_icon: MediaConversion
    widget_background: MediaConversion
  }
  content: Content
}
export const useSpecialStore = defineStore('special', {
  state: () => ({
    specials: null as Special[] | null,
    fetched: false,
    failed: false
  }),

  actions: {
    async fetch() {
      try {
        const data = await useApi().$get('/specials')

        if (data.length === 0) {
          this.failed = true
        }

        this.specials = data
        this.fetched = true
      } catch (e) {
        this.failed = true
      }
    }
  },

  getters: {
    getSpecial: (state) => (slug: string | undefined) =>
      state.specials?.find((item) => item.slug === slug) || null,
    getMenuSpecial: (state) =>
      state.specials?.find((item) => item.menu_link !== null) || null,
    getFirstRewardCategory: (state) => (slug: string | undefined) => {
      const item = state.specials?.find((item) => item.slug === slug)

      if (item === undefined) {
        return 0
      }

      return (
        item.content.find(
          (item: Content) =>
            (item.value.length > 0 ? item.value[0].id : 0) !== 0
        ).value[0].id || 0
      )
    }
  }
})
